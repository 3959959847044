import React from 'react';
import { ColumnInstance } from 'react-table';
import { Transaction } from '../../../../domain/transactions/Transaction';
import './filters-modal.less';
import { COL_ID_CATEGORY, COL_ID_NOTE, COL_ID_PAYEE, ColumnID } from '../useTableColumns/useTableColumns';
import useCategoryFilters from '../TableFilters/useCategoryFilters';
import usePayeeFilters from '../TableFilters/PayeeFilter/usePayeeFilter';
import useNotesFilters from '../TableFilters/NotesFilter/useNotesFilter';
import useTransactionTagFilters from '../TableFilters/useTransactionTagFilters';
import { AdvancedModal } from '../../../common/Modal';
import { Button } from '../../../common/Button/Button';

type Props = {
	isOpen: boolean;
	onClose: () => void;
	columns: ColumnInstance<Transaction>[];
};

const FiltersModal = (props: Props) => {
	const categoryCol = props.columns.find(col => col.id === COL_ID_CATEGORY);
	const payeeCol = props.columns.find(col => col.id === COL_ID_PAYEE);
	const notesCol = props.columns.find(col => col.id === COL_ID_NOTE);
	const tagCol = props.columns.find(col => col.id === ColumnID.COL_ID_TAGS);

	if (!payeeCol || !notesCol || !tagCol) {
		throw new Error('Cannot find table column for filters');
	}

	const categoryFilters = useCategoryFilters(categoryCol);
	const payeeFilters = usePayeeFilters(payeeCol);
	const notesFilters = useNotesFilters(notesCol);
	const tagFilters = useTransactionTagFilters(notesCol);

	return <AdvancedModal
		open={props.isOpen}
		contentPadding={false}
		actions={
			<>
				<Button
					onClick={() => {
						categoryFilters?.setFilterValue(undefined)
						payeeFilters.setFilterValue(undefined)
						notesFilters.setFilterValue(undefined)
						tagFilters.setFilterValue(undefined)
						props.onClose()
					}}
					label={'Clear All'}
					type={'warning'}
				/>
				<Button onClick={props.onClose} label={'Apply Filters'} type={'success'} />
			</>
		}
		onClose={props.onClose}
		title={"Filters"}
	>
		<div>
			<div className="table-tools__each-filter">
				{categoryCol?.render('Filter')}
			</div>
			<div className="table-tools__each-filter">
				{payeeCol.render('Filter')}
			</div>
			<div className="table-tools__each-filter">
				{notesCol.render('Filter')}
			</div>
			<div className="table-tools__each-filter">
				{tagCol.render('Filter')}
			</div>
		</div>
	</AdvancedModal>
};

export default FiltersModal;
