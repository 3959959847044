import {FormSplit} from "../../../view/accounts/AddTransactionRow/SplitRow";

type RemainingToAssign = {
    remainingInflow: number,
    remainingOutflow: number,
}

type SplitInterface = Pick<FormSplit, "inflow" | "outflow">

const getSplitAmountRemaining = (transactionInflow: number, transactionOutflow: number, splits: SplitInterface[]): RemainingToAssign => {
    const totalSplitsOutflow = splits.reduce((acc, split) => acc + split.outflow, 0);
    const totalSplitsInflow = splits.reduce((acc, split) => acc + split.inflow, 0);

    if (transactionOutflow > 0) {
        const remaining = transactionOutflow - totalSplitsOutflow + totalSplitsInflow
        return {
            remainingInflow: remaining < 0 ? Math.abs(remaining) : 0,
            remainingOutflow: remaining > 0 ? Math.abs(remaining) : 0
        }
    } else {
        const remaining = transactionInflow - totalSplitsInflow + totalSplitsOutflow
        return {
            remainingInflow: remaining > 0 ? Math.abs(remaining) : 0,
            remainingOutflow: remaining < 0 ? Math.abs(remaining) : 0
        }
    }
}

export default getSplitAmountRemaining;