import * as Yup from 'yup';
import { TransactionSplit } from '../../../../domain/transactions/Transaction';
import getSplitAmountRemaining from "./../../../../domain/transactions/util/getSplitAmountRemaining";
import { ObjectSchema, ValidationError } from 'yup';
import { dangerouslyRound } from '../../../../domain/util/currency';
import {Account} from "../../../../domain/accounts/Account";
import {AccountOption} from "../../../store/accounts/hooks/useAccountOptions";

const getSelectedAccount = (account: AccountOption, accounts: Map<Account['uuid'], Account>) => {
	const accountUUID = account?.value;
	if (!accountUUID) return null;

	return accounts.get(accountUUID);
}

const getValidationSchema = (accounts: Map<Account['uuid'], Account>) => {
	return Yup.object()
		.required()
		.shape({
			date: Yup.object().required('Required'),
			category: Yup.object().when(
				['splits', 'account', 'isTransfer'],
				(splits: TransactionSplit[], account: AccountOption, isTransfer: boolean, currentSchema: ObjectSchema) => {
					const selectedAccount = getSelectedAccount(account, accounts);
					if (selectedAccount?.isOffBudget) {
						return currentSchema.nullable().notRequired();
					}

					if (isTransfer && !selectedAccount?.isOffBudget) {
						return currentSchema.nullable().notRequired();
					}

					if (splits.length === 0) {
						return currentSchema.required().shape({
							label: Yup.string().required(),
							value: Yup.string()
								.required()
								.notOneOf(['0']),
						});
					}

					return currentSchema.nullable();
				}
			),
			account: Yup.object()
				.shape({
					label: Yup.string(),
					value: Yup.string().required(),
				})
				.required('Required'),
			transferToAccount: Yup.object().when(
				'isTransfer',
				(isTransfer: boolean, currentSchema: ObjectSchema) => {
					if (isTransfer) {
						return currentSchema.required().shape({
							label: Yup.string().required(),
							value: Yup.string()
								.required()
								.notOneOf(['0']),
						});
					}

					return currentSchema.nullable();
				}
			),
			inflow: Yup.number().required(),
			outflow: Yup.number().required(),
			profileUUID: Yup.string().required('Required'),
			status: Yup.string().required('Required'),
			splits: Yup.array()
				.defined()
				.of(
					Yup.object()
						.required()
						.shape({
							category: Yup.object()
								.required()
								.shape({
									label: Yup.string().required(),
									value: Yup.string()
										.required()
										.notOneOf(['0']),
								}),
							note: Yup.string(),
							inflow: Yup.number().required(),
							outflow: Yup.number().required(),
						})
				),
			isTransfer: Yup.boolean().required('Required'),
			tags: Yup.array().of(Yup.string()),
		})
		.test('splits', `splits don't add up`, formValue => {
			if (!formValue) {
				return false;
			}

			if (formValue.splits.length === 0) {
				return true;
			}

			const remaining = getSplitAmountRemaining(
				formValue.inflow,
				formValue.outflow,
				formValue.splits
			);

			if (
				dangerouslyRound(remaining.remainingOutflow) === 0 &&
				dangerouslyRound(remaining.remainingInflow) === 0
			) {
				return true;
			}

			return new ValidationError(`splits don't add up`, formValue.splits, `splits`);
		});
}

export default getValidationSchema;
